<template>
  <div>
      <div class="modal-container">
        <div class="transfer-container">
          <el-card shadow="never" class="card-container">
            <div slot="header" class="clearfix">
              <span>所有{{ transferConfig.title }}</span>
            </div>
              <storeTable :directSystemId="directSystemId" :bsDirectSystemId="bsDirectSystemId" :invoiceId="invoiceId" :selectStore="selectKeys" ref="storeTable"/>
          </el-card>
        </div>
        <div class="button-container">
          <div class="buttons">
            <!-- <el-button @click="addCurrentAndLower"
              >添加当前及以下{{ transferConfig.title }}<i class="el-icon-arrow-right"></i
            ></el-button> -->
            <el-button @click="addCurrent" :disabled="disabled"
              >添加{{ transferConfig.title }}<i class="el-icon-arrow-right"></i
            ></el-button>
            <el-button :disabled ="disabled" @click="removeTableData" icon="el-icon-arrow-left">移除</el-button>
          </div>
        </div>
        <div class="transfer-container">
          <el-card shadow="never" class="card-container">
            <div slot="header" class="clearfix">
              <span>已选{{ transferConfig.title }}</span>
            </div>
            <div>
              <vxe-table
                border
                :disabled ="disabled"
                show-overflow
                :data="selectData"
                row-id="id"
                @checkbox-all="selectAllEvent"
                @checkbox-change="selectChangeEvent"
              >
                <vxe-table-column type="checkbox" width="60"></vxe-table-column>
                <vxe-table-column field="sort" width="60" title="序号">
                  <template v-slot="{ rowIndex }">
                    {{ rowIndex + 1 }}
                  </template>
                </vxe-table-column>
                <vxe-table-column field="storeName" title="门店名称"></vxe-table-column>
              </vxe-table>
            </div>
          </el-card>
        </div>
      </div>
  </div>
</template>

<script>
import request from '@/found/utils/request';
import debounce from '@/found/utils/debounce';
import storeTable from './table';

export default {
  components: {
    storeTable,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    directSystemId: String,
    invoiceId: String,
    bsDirectSystemId: String,
  },
  computed: {
    selectKeys() {
      return this.selectData.map((item) => item.kmsStoreId);
    },
  },
  data() {
    return {
      transferConfig: {
        title: '',
        api: '/kms/kmstenantrydirectstore/list',
      },
      defaultProps: {
        children: 'childList',
        label: 'name',
        value: 'id',
      },
      treeData: [],
      defaultExpandAll: false,
      showTree: true,
      filterText: '',
      selectData: [],
      checkedKeys: [],
      selectTableData: [],
      loading: false,
    };
  },
  watch: {
    value(val) {
      this.selectData = val.map((item) => ({
        ...item,
        id: item.kmsStoreId,
      }));
      this.$emit('input', this.selectData);
    },
    filterText(v) {
      if (v) {
        this.defaultExpandAll = true;
      } else {
        this.defaultExpandAll = false;
      }
      this.showTree = false;
      this.searchDebounce();
    },
  },
  created() {

  },
  mounted() {
    this.searchDebounce = debounce(() => this.getTreeList(), 500);
  },
  methods: {
    onClose() {
      this.modalConfig.visible = false;
    },
    onOk() {
      this.modalConfig.visible = false;
      this.$emit('onOk', this.selectData);
    },
    selectAllEvent({ checked, records }) {
      this.selectTableData = records;
    },
    selectChangeEvent({ checked, records }) {
      this.selectTableData = records;
    },
    // // 获取数据
    // getTreeList() {
    //   this.treeData = [];
    //   this.selectData = [];
    //   if (!this.directSystemId || !this.invoiceId) {
    //     return;
    //   }
    //   const data = {
    //     name: this.filterText,
    //     directSystemId: this.directSystemId,
    //     invoiceId: this.invoiceId,
    //   };
    //   this.loading = true;
    //   request.post(this.transferConfig.api, data).then((res) => {
    //     if (res.success) {
    //       this.getSelectData(res.result);
    //       console.log(this.selectData);
    //       this.treeData = this.setDisabled(res.result);
    //       this.showTree = true;
    //       this.loading = false;
    //     }
    //   });
    // },
    // // 获取详情已经选择数据
    // getSelectData(data) {
    //   data.forEach((v) => {
    //     const item = v;
    //     if (item.childList.length > 0) {
    //       this.getSelectData(item.childList);
    //     }
    //     if (this.value && this.value.includes(item.id) && !this.selectData.map((i) => i.id).includes(item.id)) {
    //       this.selectData.push(item);
    //     }
    //   });
    // },
    // 获取选中数据
    getCheckedNodes() {
      return JSON.parse(JSON.stringify(this.$refs.storeTable.selectRow));
    },
    // 添加当前组织
    addCurrent() {
      const tempData = this.getCheckedNodes();
      const selectKeys = this.selectData.map((item) => item.id);
      console.log(this.selectData);
      console.log(selectKeys);
      console.log(tempData);
      tempData.forEach((item) => {
        if (!selectKeys.includes(item.id)) {
          this.selectData.push({ ...item, kmsStoreId: item.id });
        }
      });
      console.log(this.selectData);
      this.checkedKeys = this.selectData.map((item) => item.id);
      this.$emit('input', this.selectData);
    },
    // 设置左侧树禁用
    setDisabled(array) {

    },
    // 移除
    removeTableData() {
      const selectTableKeys = this.selectTableData.map((item) => item.id);
      this.selectData = this.selectData.filter((item) => !selectTableKeys.includes(item.id));
      this.checkedKeys = this.selectData.map((item) => item.id);
      // this.treeData = this.setDisabled(this.treeData);
      this.$emit('input', this.selectData);
    },
  },
};
</script>

<style lang="less" scoped>
.modal-container {
  display: flex;
  height: 500px;
  .transfer-container {
    flex: 1 0 0;
    .card-container {
      height: 100%;
      overflow-y: scroll;
    }
  }
  .button-container {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .buttons {
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
