import TablePage from '../../../../../components/table_page';
import Modal from '../../../../../components/modal';
import request from '../../../../../utils/request';
import Form from '../form';

export default {
  extends: TablePage,
  props: {
    treeCode: String,
    treeName: String,
    bsDirectId: String,
    directId: String,
  },
  data() {
    return {
      requestUrl: '/kms/kmsgrabrule/list',
      params: {
        bsDirectSystemId: '',
      },
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    Modal,
    Form,
  },
  watch: {
    async treeCode(val) {
      console.log(val);
      if (!val) {
        return false;
      }
      this.params.bsDirectSystemId = this.bsDirectId;
      console.log(this.params);
      const invoiceIdData = await request.post('/kms/kmsAdmin/tenantry/direct/orderType/list', {
        // directSystemId: val,
        directId: this.directId,
        // bsDirectSystemId
        // bsDirectId: this.bsDirectId,
      });
      const selectData = invoiceIdData.result.map((v, i) => {
        const item = {
          label: v.orderTypeName,
          value: v.orderType,
          bsInvoiceId: v.bsInvoiceId,
        };
        if (i === 0) {
          // this.formData.invoiceId = v.id;
          this.formData.bsDirectId = this.bsDirectId;
          this.formData.bsInvoiceId = v.bsInvoiceId;
          // this.searchFormData.invoiceId = v.id;
          this.searchFormData.bsInvoiceId = v.bsInvoiceId;
        }
        return item;
      });
      this.searchList.map((v) => {
        const item = v;
        if (item.field === 'orderTypeId') {
          item.render.options = selectData;
        }
        return item;
      });
      this.getList();
    },
  },
  created() {
    this.getConfigList('kms_grasping_rules_table');
  },
  methods: {
    // beforeGetList() {
    //   if (this.params.directSystemId) {
    // if (!this.searchFormData.invoiceId) {
    //   const invoiceId = this.searchList[1] && this.searchList[1].render && this.searchList[1].render.options && this.searchList[1].render.options[0] && this.searchList[1].render.options[0].value;
    //   const bsInvoiceId = this.searchList[1] && this.searchList[1].render && this.searchList[1].render.options && this.searchList[1].render.options[0] && this.searchList[1].render.options[0].bsInvoiceId;
    //   this.formData.invoiceId = invoiceId;
    //   this.formData.bsDirectId = this.bsDirectId;
    //   this.formData.bsInvoiceId = bsInvoiceId;
    //   this.searchFormData.invoiceId = invoiceId;
    //   this.searchFormData.bsInvoiceId = bsInvoiceId;
    // }
    // if (!this.searchFormData.invoiceId) {
    //   return false;
    // }
    //     return true;
    //   }
    //   return false;
    // },
    beforeSearchEvent(val) {
      if (val.$event.type === 'reset') {
        this.$emit('reset');
      }
      return true;
    },
    beforeModalClick({ val }) {
      // if (val.code === 'enable' || val.code === 'disable' || val.code === 'delete') {
      //   this.resetTree();
      //   return false;
      // }
      return true;
    },
    modalClick({ val, row }) {
      if (!this.beforeModalClick({ val, row })) return;
      this.formConfig = {};
      if (val.code === 'add') {
        this.formConfig = {
          ...val,
        };
        this.formName = 'Form';
        this.modalConfig.title = '新增';
        this.openFull();
      } else if (val.code === 'edit') {
        this.formName = 'Form';
        this.formConfig = {
          ...val,
          ...row,
          code: 'edit',
        };
        this.modalConfig.title = '编辑';
        this.openFull();
      } else if (val.code === 'view') {
        this.formName = 'Form';
        this.formConfig = {
          ...val,
          ...row,
          code: 'view',
        };
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
  },
};
